import { useInView } from "react-intersection-observer";
// import careersCrossPlatform from "./../careers-cross-platform.png";
import careersConsole from "./../careers-console.webp";
import careersHeadphones from "./../careers-headphones.webp";
import careersKeyboard from "./../careers-keyboard.webp";
import careersMouse from "./../careers-mouse.webp";
// import Button from "./Button";

const CareersSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <div
      id="careers"
      className="relative z-20 flex h-auto w-full items-center bg-gradient-to-b from-[#08080f] to-brand-dark py-8 lg:py-48 2xl:py-72"
    >
      <div className="container inset-0 z-10 mx-auto flex h-auto w-full flex-col items-center justify-center px-8 lg:flex-row">
        <div className="logo-wrapper grid h-full w-full grid-cols-2 grid-rows-2 pb-16 lg:w-2/3 lg:px-16 lg:pb-0 xl:px-28">
          <div className="relative flex h-auto w-full items-center justify-center">
            <img
              ref={scrollRef}
              data-inview={scrollInView}
              className={`w-full object-contain lg:absolute lg:-bottom-12 lg:right-0 lg:aspect-square ${
                scrollInView ? "fade-in-up" : "opacity-0"
              }`}
              src={careersConsole}
              alt="Conjure Games - Switch Console"
            />
          </div>
          <div className="relative flex h-auto w-full items-center justify-center">
            <img
              ref={scrollRef}
              data-inview={scrollInView}
              className={`w-2/3 object-contain lg:absolute lg:left-0 lg:bottom-0 lg:aspect-square ${
                scrollInView ? "fade-in-up" : "opacity-0"
              }`}
              src={careersHeadphones}
              alt="Conjure Games - Headphones"
            />
          </div>
          <div className="relative flex h-auto w-full items-center justify-center">
            <img
              ref={scrollRef}
              data-inview={scrollInView}
              className={`w-full object-contain lg:absolute lg:right-0 lg:-top-8 lg:aspect-square ${
                scrollInView ? "fade-in-down" : "opacity-0"
              }`}
              src={careersKeyboard}
              alt="Conjure Games - Keyboard"
            />
          </div>
          <div className="relative flex h-auto w-full items-center justify-center">
            <img
              ref={scrollRef}
              data-inview={scrollInView}
              className={`w-1/2 object-contain lg:absolute lg:left-8 lg:top-8 lg:aspect-square ${
                scrollInView ? "fade-in-down" : "opacity-0"
              }`}
              src={careersMouse}
              alt="Conjure Games - Mouse"
            />
          </div>
        </div>
        <div
          ref={scrollRef}
          data-inview={scrollInView}
          className={`w-full text-white lg:w-1/3 xl:pt-16 ${
            scrollInView ? "fade-in-up" : ""
          }`}
        >
          <h2 className="relative mb-12 w-fit font-neo text-4xl font-medium uppercase italic after:absolute after:-bottom-6 after:left-0 after:h-[2px] after:w-full after:bg-gradient-to-r after:from-brand-alt after:to-brand-main after:content-[''] lg:text-5xl xl:text-6xl">
            Conjure Games
          </h2>
          <p className="mb-6 w-full font-open text-base md:w-3/4 lg:mb-12 lg:w-full lg:text-lg">
            What if you could shape a world with your choices, crafting epic
            tales of heroism where every decision is yours to make—Are you ready
            to be the heart of our next adventure?
          </p>
          {/* <h2 className="relative mb-12 w-fit font-neo text-4xl font-medium uppercase italic after:absolute after:-bottom-6 after:left-0 after:h-[2px] after:w-full after:bg-gradient-to-r after:from-brand-alt after:to-brand-main after:content-[''] lg:text-5xl xl:text-6xl">
            We're hiring
          </h2>
          <p className="mb-6 w-full font-open text-base md:w-3/4 lg:mb-12 lg:w-full lg:text-lg">
            Are you ready to embark on a new career adventure? Conjure a career
            that is simply magical... with us!
          </p> */}
          {/* <div className="pb-24">
            <Button
              url={"https://boards.greenhouse.io/conjuregames"}
              text={"Apply Now"}
              type="main"
              target="_blank"
            />
          </div> */}
        </div>
      </div>
      <div className="pointer-events-none absolute inset-0 h-auto w-full overflow-hidden">
        <div className="delay absolute top-[15%] left-24 -z-30 h-48 w-48 animate-flare rounded-full bg-gradient-to-bl from-brand-main to-brand-alt opacity-15 blur-xl md:left-[25vw] md:top-[25%] md:h-72 md:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-2000 absolute top-[25%] left-[30vw] -z-30 h-48 w-48 animate-flare rounded-full bg-gradient-to-tl from-brand-dark via-brand-main to-brand-alt opacity-15 blur-xl md:top-[40%] md:h-72 md:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-4000 absolute top-[15%] right-24 -z-30 h-48 w-48 animate-flare rounded-full bg-gradient-to-tr from-brand-main to-brand-alt opacity-15 blur-xl md:left-[35vw] md:top-[25%] md:h-72 md:w-72 2xl:h-96 2xl:w-96"></div>
      </div>
    </div>
  );
};

export default CareersSection;

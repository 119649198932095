import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="55px"
      viewBox="0 0 55.7 155.6"
    >
      <g transform="translate(36 34)">
        <g transform="translate(0 20)">
          <g transform="translate(-36 -44)">
            <linearGradient
              id="Path_4-2_00000150805376875447950420000003073560164210979978_"
              x1="-93.538"
              x2="-93.538"
              y1="200.698"
              y2="199.698"
              gradientTransform="matrix(48 0 0 -142.323 4517.674 28566.637)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#FF6400"></stop>
              <stop offset="1" stopColor="#9600FF"></stop>
            </linearGradient>
            <path
              fill="url(#Path_4-2_00000150805376875447950420000003073560164210979978_)"
              d="M3.9 2.6V145l12-20.8 12-20.8 12 20.8 12 20.8V2.6h-48zm38.3 77.8c.7 0 1.4.2 1.9-.6.1-.2.5-.3.8-.4.1-.1.2-.2.3-.1.1.2.1.4 0 .5-.5.6-1.2 1.2-1.8 1.7-1.5 1.1-3.1 2-4.9 2.7-2.2.8-4.5 1.4-6.9 1.6-.8.1-1.4.1-2.2.2h-1.3l-1.1-.1c-1.7-.2-3.4-.7-5-1.4-2.5-1-4.8-2.4-6.8-4.2-1.4-1.1-2.4-2.7-2.9-4.4-.1-.2-.1-.9-.5-.8-.2 0-.3.6-.5.9-.1.4-.1.8 0 1.1 0 .3-.1.5-.3.7l-.3.3c-.1-.1-.2-.2-.2-.3-.1-.3 0-.6-.2-.9-.6-1-.9-2.2-.9-3.5-.3-1.9-.2-3.9.3-5.8.1-.5.5-.8.4-1.4-.1-.5-.1-1 .1-1.5.9-2.1 1.4-4.3 2.6-6.3.6-.9 1-2 2.1-2.5 1.1-.6 2.1-1.4 2.9-2.3.4-.4.9-.7 1.5-1 2.1-.9 4.3-1.6 6.5-1.9 1.2-.2 2.4-.7 3.7-.5.1 0 .2-.1.3-.2-.1-.1-.1-.2-.2-.3-.5-.4-1.1-.8-1.7-1.1-.6-.2-1.2-.4-1.9-.6.7-.4 1.5-.5 2.3-.2.8.4 1.6.2 2.3.5 1.3.5 2.6.9 3.9 1.3 2.6.8 4.6 2.5 6.6 4.2.5.4.7 1.2 1.3 1.6.5.5.9 1 1.2 1.7 2 2.7 3.1 6.1 2.9 9.5v2.5c0 2.1-.5 4.1-1.5 5.9-.7 1.7-1.7 3.3-3 4.6-.2.1-.2.3-.2.5.1.2.2.3.4.3"
            ></path>
          </g>
          <linearGradient
            id="Path_3_00000145016211948201440140000000690002122263323551_"
            x1="-126.096"
            x2="-126.096"
            y1="148.092"
            y2="147.092"
            gradientTransform="matrix(28.6668 0 0 -27.9889 3607.778 4155.32)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#FF6400"></stop>
            <stop offset="1" stopColor="#9600FF"></stop>
          </linearGradient>
          <path
            fill="url(#Path_3_00000145016211948201440140000000690002122263323551_)"
            d="M7 20.1c-.1-.9-.4-1.7-.8-2.4-.1-.1-.1-.2-.2-.3-.1.1-.2.2-.2.3-.1.6 0 1.3.1 1.9.7 1.7.2 3.3-.4 4.8-.8 1.7-1.8 3.3-3 4.8-1 1.3-2.3 2.3-3.9 2.9-.3.2-.6-.1-.9.2-.1.1-.3.3-.4.1-.1-.2-.1-.4.1-.5.5-.5 1.2-.6 1.6-1.2.9-1.4 1.8-2.8 2.6-4.3.5-1.2.9-2.5 1.1-3.8.5-2.6-.8-4.8-2.1-6.8-1-1.6-2.5-2.9-4.2-3.6-2-.8-3.9-1.9-5.7-1.6-1.7.1-3-.1-4.1.4-1.4.7-3 .9-4.3 1.9-.7.5-1.2 1.3-2.1 1.5-.4.1-.3.5-.3.8.2-.1.5.1.7-.1 1.1-.9 2.3-1.5 3.7-1.9.6-.2 1.1-.4 1.7-.6.5-.3 1.5.2 2.1-.1.8-.3 1.6-.4 2.4-.3.6.8 1.8-.1 2.4.7 1.4.2 2.4 1.1 3.6 1.5.6.2.5.5.5.8 0 .7-.6.5-.9.4-1.4-.5-3-.8-4.5-.8h-1.9c-.7-.1-1.1.6-1.8.4-.9 0-1.8.2-2.6.6-2 .7-4.1 3.4-5.3 5.3-1.7 2.6-1.2 5.3-.5 8 .3 1.1.8 2.1 1.4 3.1.7 1.4 1.8 2.6 3.1 3.5 1.6 1.1 3.4 1.9 5.2 2.3.6.3 1.3.5 2 .4.2 0 .5 0 .5-.2-.2-.2-.5-.2-.7-.2-2.3-.4-4.4-1.6-6-3.2-1.8-1.6-3-3.8-3.3-6.2-.2-2-.3-2.7.4-4.4.1-.2 0-.5.2-.5.3 0 .3.4.2.6-.1.9 0 1.8.4 2.5.3 1.1.7 2.2 1.4 3.1 1.7 1.8 3.3 3.7 5.7 4.5 1.1.6 2.3.8 3.5.8.7-.1 1.4 0 2.1 0 1.5-.1 3-.5 4.3-1.4 1.5-1 3-2.1 4.2-3.4 2-2 3.1-4.8 3.1-7.6.2-.9-.2-1.8-.2-2.7"
          ></path>
          <linearGradient
            id="Path_2_00000174602370649085457780000009491320318214760356_"
            x1="544.574"
            x2="544.574"
            y1="-721.551"
            y2="-722.551"
            gradientTransform="matrix(.3607 0 0 -.3406 -217.126 -230.245)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#FF6400"></stop>
            <stop offset="1" stopColor="#9600FF"></stop>
          </linearGradient>
          <path
            fill="url(#Path_2_00000174602370649085457780000009491320318214760356_)"
            d="M-20.7 15.8c.1 0 .1-.1.2-.2v-.2c-.1 0-.2.1-.3.1-.1.2-.1.3.1.3"
          ></path>
          <linearGradient
            id="Path_1_00000154413897826451926990000013826963572410820268_"
            x1="476.572"
            x2="476.572"
            y1="-588.963"
            y2="-589.963"
            gradientTransform="matrix(.4008 0 0 -.401 -211.347 -221.052)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#FF6400"></stop>
            <stop offset="1" stopColor="#9600FF"></stop>
          </linearGradient>
          <path
            fill="url(#Path_1_00000154413897826451926990000013826963572410820268_)"
            d="M-20.5 15.5c.2 0 .4-.1.4-.3v-.1c-.2 0-.4.1-.4.3v.1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;

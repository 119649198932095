import React from "react";
import { useInView } from "react-intersection-observer";

const MissionSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <div
      id="mission"
      className="slanted-down relative flex h-auto w-full items-center after:absolute after:inset-0 after:-z-20 after:h-full after:w-full after:content-['']"
    >
      <div className="z-1 absolute top-0 left-0 h-[2px] w-full bg-gradient-to-l from-brand-alt_dark to-brand-dark"></div>

      <div className="z-1 absolute inset-0 h-full w-full origin-top-right skew-y-[4deg] bg-gradient-to-t from-brand-alt via-black to-black opacity-10 lg:opacity-20"></div>

      <div className="z-1 container mx-auto flex w-full flex-col items-center px-8 lg:flex-row">
        <div className="flex w-full transform flex-col text-white lg:w-1/2 lg:-translate-y-16 lg:flex-row xl:pt-16">
          <div className="w-0 lg:w-1/6"></div>
          <div
            ref={scrollRef}
            data-inview={scrollInView}
            className={`flex w-full flex-col justify-center py-48 sm:w-5/6 lg:h-fit lg:py-[20vh] ${
              scrollInView ? "fade-in-up" : ""
            }`}
          >
            <h2 className="relative mb-12 w-fit font-neo text-4xl font-medium uppercase italic after:absolute after:-bottom-6 after:left-0 after:h-[2px] after:w-full after:bg-gradient-to-r after:from-brand-alt after:to-brand-main after:content-[''] lg:text-5xl xl:text-6xl">
              The Mission
            </h2>
            <p className="mb-2 w-full font-open text-base lg:mb-4 lg:text-lg xl:w-5/6">
              We create games that put our players and the characters they
              create at the center of the story!
            </p>
            <p className="mb-6 w-full font-open text-base lg:mb-12 lg:text-lg xl:w-5/6">
              Let's entertain the world together!
            </p>
          </div>
        </div>

        <div className="w-full lg:w-1/2"></div>
      </div>
      <div className="pointer-events-none absolute inset-0 h-auto w-full overflow-hidden">
        <div className="delay absolute top-[22%] left-[24vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-bl from-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-2000 absolute top-[37%] left-[19vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-tl from-brand-dark via-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-4000 absolute top-[17%] left-[12vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-tr from-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
      </div>
    </div>
  );
};

export default MissionSection;

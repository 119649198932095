import { ChangeEvent, FormEvent, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useInView } from "react-intersection-observer";

type FormState = {
  email: string;
  name: string;
};

type ServiceMessage = {
  class: string;
  text: string;
};

const UpdatesSection = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  const formId = "ewSJL5eE";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = "6Lel024kAAAAAJSbre5icW7zDbRvCp16pS3aXxbM";
  const recaptchaRef = useRef<any>();

  const initialFormState = {
    email: "",
    name: "",
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken,
    };
    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        class:
          "relative z-40 bg-gradient-to-r from-green-800 to-green-500 opacity-90",
        text: "Thanks, someone will be in touch shortly.",
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      // console.log(error);
      setMessage({
        class:
          "relative z-40 bg-gradient-to-r from-red-800 to-red-500 opacity-90",
        text: "Sorry, there was a problem. Please try again or email our support directly.",
      });
    }
  };

  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const formKey = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[formKey] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  return (
    <div
      id="updates"
      className="slanted-up relative flex h-auto w-full items-center justify-center after:absolute after:inset-0 after:-z-20 after:h-full after:w-full after:content-['']"
    >
      <div className="z-1 absolute bottom-0 left-0 h-[2px] w-full bg-gradient-to-r from-brand-main to-brand-dark"></div>

      <div className="z-1 absolute inset-0 h-full w-full origin-bottom-left skew-y-[4deg] bg-gradient-to-r from-brand-main via-[#D82F2F] to-black opacity-25"></div>

      <div className="z-1 container mx-auto flex w-full flex-col items-center px-8 lg:flex-row">
        <div className="hidden h-52 w-0 lg:inline-flex lg:w-1/2"></div>

        <div className="flex w-full flex-col lg:w-1/2 lg:flex-row lg:pr-[10%] xl:pt-16">
          <div
            ref={scrollRef}
            data-inview={scrollInView}
            className={`flex w-full flex-col justify-center py-24 sm:py-48 lg:h-fit lg:py-[20vh] ${
              scrollInView ? "fade-in-up" : ""
            }`}
          >
            <h2 className="relative mb-12 w-fit font-neo text-4xl font-medium uppercase italic text-white after:absolute after:-bottom-6 after:left-0 after:h-[2px] after:w-full after:bg-gradient-to-r after:from-brand-alt after:to-brand-main after:content-[''] lg:text-5xl xl:text-6xl">
              Stay Informed
            </h2>
            <p className="relative z-30 mb-4 font-open text-base text-white lg:mb-8 lg:text-lg xl:w-3/4">
              Join our mailing list to get the latest updates and be the first
              to know about what we're working on!
            </p>
            {message && (
              <div className={`my-4 w-full p-4 text-white ${message.class}`}>
                {message.text}
              </div>
            )}
            <form onSubmit={submitForm} className="relative z-40 flex flex-col">
              <div className="my-2 flex w-full flex-col xl:w-3/4">
                <label
                  className="mb-2 font-neo text-lg uppercase text-white lg:text-2xl"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  required
                  placeholder="Email"
                  onChange={updateFormControl}
                  type="email"
                  className="h-12 rounded-bl-[24px] border-2 border-brand-main p-4 text-brand-main outline-none focus:border-brand-vibrant focus:text-brand-vibrant"
                  id="email"
                  value={formState.email}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-2 lg:mb-8 lg:flex-row lg:items-end lg:gap-4 xl:w-3/4">
                <div className="my-2 flex w-full flex-col lg:w-2/3">
                  <label
                    className="mb-2 font-neo text-lg uppercase text-white lg:text-2xl"
                    htmlFor="name"
                  >
                    Full name:
                  </label>
                  <input
                    required
                    placeholder="Full Name"
                    onChange={updateFormControl}
                    type="text"
                    className="h-12 rounded-bl-[24px] border-2 border-brand-main p-4 text-brand-main outline-none focus:border-brand-vibrant focus:text-brand-vibrant"
                    id="name"
                    value={formState.name}
                  />
                </div>

                <button
                  disabled={submitting}
                  className="relative z-10 mb-2 mt-4 flex h-12 w-full min-w-fit items-center justify-center whitespace-nowrap rounded-bl-[24px] bg-brand-main p-2 px-6 py-2 font-neo text-lg font-medium uppercase tracking-widest text-[#eeeeee] opacity-90 transition-all duration-300 before:absolute before:inset-0 before:-z-10 before:h-full before:w-full before:rounded-bl-[24px] before:bg-brand-main before:content-[''] after:absolute after:inset-0 after:-z-20 after:h-full after:w-[97%] after:transform after:rounded-bl-[24px] after:border-r-[8px] after:border-brand-main_darkest after:bg-brand-main_dark after:transition-transform after:duration-300 hover:bg-blue-900 hover:text-[#ffffff] hover:opacity-100 hover:after:translate-x-5 sm:min-w-fit lg:w-1/3 lg:text-xl"
                >
                  {submitting ? "Submitting..." : "Submit"}
                </button>
              </div>

              <div className="mt-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={updateRecaptchaToken}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="pointer-events-none absolute inset-0 h-auto w-full overflow-hidden">
        <div className="delay absolute top-[30%] right-[36vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-bl from-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-2000 absolute top-[47%] right-[31vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-tl from-brand-dark via-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
        <div className="animation-delay-4000 absolute top-[32%] right-[26vw] -z-30 h-24 w-24 animate-flare rounded-full bg-gradient-to-tr from-brand-main to-brand-alt opacity-15 blur-xl lg:h-72 lg:w-72 2xl:h-96 2xl:w-96"></div>
      </div>
    </div>
  );
};

export default UpdatesSection;

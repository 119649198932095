import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

const NotFound = () => {
  useEffect(() => {
    document.title = "Conjure Games | Privacy Policy";
  });

  const pStyle = "text-base text-white lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div
      id="#top"
      className="terms h-auto bg-gradient-to-b from-brand-dark to-brand-main_darkest py-32 lg:py-56"
    >
      <div className="container mx-auto flex max-w-6xl flex-col items-center justify-center px-8 text-center font-open text-white lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 w-full font-neo text-2xl font-bold uppercase lg:mb-8 lg:mt-4 lg:text-3xl">
          Ooops looks like you tried to conjure up a path that doesn't exist.
        </h1>

        <p className={`${pStyle}`}>
          Let's get you back{" "}
          <HashLink smooth to="/#top" className="underline">
            home.
          </HashLink>
        </p>
      </div>
    </div>
  );
};

export default NotFound;

import CareersSection from "../components/CareersSection";
import HeroSection from "../components/HeroSection";
import MissionSection from "../components/MissionSection";
import UpdatesSection from "../components/UpdatesSection.tsx";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    document.title = "Conjure Games | Let's entertain the world together!";
  });

  return (
    <div className="home">
      <HeroSection />
      <CareersSection />
      <MissionSection />
      <div className="h-auto w-full overflow-x-clip">
        <div className="angled-inset relative z-20 h-[2vh] min-h-[20px] origin-center rotate-[4deg] scale-x-105 transform bg-[#D82F2F] opacity-80 2xl:h-[4vh] 2xl:min-h-[48px]">
          <div className="absolute inset-0 h-full w-full animate-colorPulse bg-gradient-to-l from-brand-alt to-brand-main mix-blend-color"></div>
        </div>
      </div>
      <UpdatesSection />
    </div>
  );
};

export default Home;

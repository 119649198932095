import React from "react";
import LogoIcon from "./svg/LogoIcon";
// import heroImageJPG from "./../hero-image.jpg";
import heroImage from "./../hero-image.webp";
import gradientOverlay from "./../hero-gradient.png";
import { HashLink } from "react-router-hash-link";
// import Button from "./Button";
import Particles from "./Particles";

const HeroSection = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -64;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  <Particles />;
  return (
    <div className="relative flex min-h-[100vh] w-full items-center overflow-hidden pt-16 lg:justify-center">
      <div className="dust pointer-events-none absolute inset-0 z-30 h-full w-full"></div>
      <div
        className="pointer-events-none absolute inset-0 z-0 h-full w-full bg-contain opacity-80"
        style={{ backgroundImage: `url(${gradientOverlay})` }}
      ></div>

      <div className="pointer-events-none absolute inset-0 z-40 flex h-full w-full items-center justify-center">
        <div className="logo-wrapper fade-in-up w-[20vw] min-w-[320px] sm:min-w-[480px]">
          <LogoIcon />
        </div>
      </div>

      <div className="container relative z-10 mx-auto flex h-full w-full self-end px-8 pb-24 lg:justify-end">
        {/* <Button
          url={"https://boards.greenhouse.io/conjuregames"}
          text={"Now Hiring"}
          type="alt"
          target="_blank"
        /> */}
        <HashLink
          smooth
          to="/#updates"
          className="fade-in-up relative z-10 flex h-10 w-fit min-w-fit items-center justify-center whitespace-nowrap rounded-bl-[24px] bg-brand-main px-6 py-2 font-neo text-lg font-medium uppercase tracking-widest text-[#eeeeee] opacity-90 transition-all duration-300 before:absolute before:inset-0 before:-z-10 before:h-full before:w-full before:rounded-bl-[24px] before:bg-brand-main before:content-[''] after:absolute after:inset-0 after:-z-20 after:h-full after:w-[98%] after:transform after:rounded-bl-[24px] after:border-r-[8px] after:border-brand-main_darkest after:bg-brand-main_dark after:transition-transform after:duration-300 hover:text-[#ffffff] hover:opacity-100 hover:after:translate-x-5 sm:min-w-fit lg:h-12 lg:text-xl"
          scroll={(el) => scrollWithOffset(el)}
        >
          Stay Informed
        </HashLink>
      </div>

      <div
        className="fade-in-down absolute inset-0 -z-10 h-full w-full bg-cover bg-center xl:bg-center"
        style={{ backgroundImage: `url(${heroImage})` }}
      ></div>
    </div>
  );
};

export default HeroSection;

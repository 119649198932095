const Particles = () => {
  var possibleColors = [
    "#EF5E0025",
    "#EF5E0050",
    "#bbbbbb25",
    "#bbbbbb50",
    "#9600FF25",
    "#9600FF50",
    "#11015150",
    "#00000025",
  ];
  var loopDurationEnded = false;
  var width = window.innerWidth;
  // var height = window.innerHeight;
  var cursor = { x: width / 2, y: width / 2 };
  var particles = [];

  function init() {
    // bindEvents();
    setTimeout(() => {
      loop();
    }, 150);
  }

  // // Bind events that are needed
  // function bindEvents() {
  //   window.addEventListener("resize", onWindowResize);
  // }

  // function onWindowResize(e) {
  //   width = window.innerWidth;
  //   height = window.innerHeight;
  // }

  function particleLifeSpan() {
    cursor.x = Math.floor(Math.random() * 2560);
    cursor.y = Math.floor(Math.random() * 1080);

    addParticle(
      cursor.x,
      cursor.y,
      possibleColors[Math.floor(Math.random() * possibleColors.length)]
    );
  }

  function addParticle(x, y, color) {
    var particle = new Particle();
    particle.init(x, y, color);
    particles.push(particle);
  }

  function updateParticles() {
    // Updated
    for (var i = 0; i < particles.length; i++) {
      particles[i].update();
    }

    // Remove dead particles
    for (var j = particles.length - 1; j >= 0; j--) {
      if (particles[j].lifeSpan < 0) {
        particles[j].die();
        particles.splice(j, 1);
      }
    }
  }

  function loop() {
    // setTimeout(() => {
    //   loopDurationEnded = true;
    // }, 60000);

    if (loopDurationEnded) {
      console.log("Particles ");
    } else {
      particleLifeSpan();
    }
    requestAnimationFrame(loop);
    updateParticles();
  }

  /**
   * Particles
   */

  function Particle() {
    this.character = "•";
    this.lifeSpan = 240; //ms
    this.initialStyles = {
      position: "absolute",
      display: "block",
      pointerEvents: "none",
      "z-index": "10000000",
      fontSize: "14px",
      "will-change": "transform",
    };

    // Init, and set properties
    this.init = function (x, y, color) {
      this.velocity = {
        x: (Math.random() < 0.5 ? -1 : 1) * (Math.random() * 2),
        y: -1,
      };

      this.position = { x: x - 10, y: y - 20 };
      this.initialStyles.color = color;

      this.element = document.createElement("span");
      this.element.innerHTML = this.character;
      applyProperties(this.element, this.initialStyles);
      this.update();
      const particlesFrame = document.querySelector(".dust");
      particlesFrame.appendChild(this.element);
    };

    this.update = function () {
      this.position.x += this.velocity.x;
      this.position.y += this.velocity.y;
      this.lifeSpan--;

      this.element.style.transform =
        "translate3d(" +
        this.position.x +
        "px," +
        this.position.y +
        "px, 0) scale(" +
        this.lifeSpan / 150 +
        ")";
    };

    this.die = function () {
      this.element.parentNode.removeChild(this.element);
    };
  }

  /**
   * Utils
   */

  // Applies css `properties` to an element.
  function applyProperties(target, properties) {
    for (var key in properties) {
      target.style[key] = properties[key];
    }
  }

  init();
};

if (window.location.pathname === "/") {
  Particles();
} else {
  // do nothing.
}

export default Particles;

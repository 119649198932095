import { Link } from "react-router-dom";
// import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <footer id="connect" className="footer relative z-20 bg-black">
      <div className="footer-links container mx-auto flex h-48 w-full flex-col items-center justify-center gap-2 px-8 text-white">
        <p className="w-full text-center font-neo text-sm uppercase lg:text-base">
          © {new Date().getFullYear()} Conjure Games. All Rights Reserved.{" "}
        </p>
        <div className="text-sm">
          {/* <HashLink smooth to="/terms/#top">
            Terms
          </HashLink>{" "}
          |{" "}
          <HashLink smooth to="/privacy/#top">
            Privacy
          </HashLink> */}
          <Link to="/terms">Terms</Link> | <Link to="/privacy">Privacy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
